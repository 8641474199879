import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/index.js";

// Import plugins and styles
import { BootstrapVue } from "bootstrap-vue";
import VueYoutube from "vue-youtube";
import Scrollspy from "vue2-scrollspy";
import VueToast from "vue-toast-notification";
import VueCurrencyFilter from "vue-currency-filter";
import VueMask from "v-mask";
import VueSlider from "vue-slider-component";
import VueLazyload from "vue-lazyload";
import VueScrollTo from "vue-scrollto";

import "vue-toast-notification/dist/theme-sugar.css";
import "vue-select/dist/vue-select.css";
import "@/assets/scss/style.scss";
import "@/assets/scss/new_design/style.scss";
import "@/assets/scss/new_design/responsive.scss";
import "@/assets/css/materialdesignicons.min.css";
import "vue2-datepicker/index.css";
import "vue-slider-component/theme/default.css";
import "aos/dist/aos.css";

import ApiService from "@/services/api.service";
import "./services/axios"; // Ensure this import is after other services
import useGlobal from "@/globals/useGlobal"; // Global mixin

// Register global components
Vue.component("VueSlider", VueSlider);
Vue.mixin(useGlobal);

// Use plugins
Vue.use(VueLazyload);
Vue.use(VueYoutube);
Vue.use(VueMask);
Vue.use(Scrollspy);
Vue.use(VueScrollTo);
Vue.use(VueToast, { position: "top" });
Vue.use(BootstrapVue);
Vue.use(VueCurrencyFilter, {
  symbol: "c",
  thousandsSeparator: " ",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
});

Vue.config.productionTip = false;

// Mount interceptor for API service
ApiService.mount401Interceptor();

// Set default locale if not set
if (!localStorage.getItem("locale")) {
  localStorage.setItem("locale", "uz_latn");
}

// Initialize Vue instance inside a function instead of top-level async
function initApp() {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

// Execute the initialization
initApp();
