import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const AVAILABLE_LOCALES = ["uz_latn", "ru", "uz_cyrl"];
const storedLocale = localStorage.getItem("locale");

// Determine the locale to use
const locale = AVAILABLE_LOCALES.includes(storedLocale)
  ? storedLocale
  : AVAILABLE_LOCALES[0];

// Initialize with empty messages
const messages = {
  uz_latn: {},
  uz_cyrl: {},
  ru: {},
};

const i18n = new VueI18n({
  locale,
  messages,
});

// Function to dynamically load locale messages
async function loadLocaleMessages(locale) {
  try {
    const loadedMessages = await import(`./locales/${locale}.json`);
    i18n.setLocaleMessage(locale, { ...loadedMessages.default });

  } catch (error) {
    console.error(`Failed to load messages for locale '${locale}':`, error);
  }
}

// Load the messages for the current locale
loadLocaleMessages(locale);


export default i18n;
