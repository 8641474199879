<template>
  <div>
    <!-- <MainLoader v-if="loading" /> -->

    <!-- <Snowflake /> -->

    <AdFox />

    <router-view />
  </div>
</template>

<script>
import AdFox from "@/components/AdFox.vue";
// import Snowflake from "@/components/Snowflake.vue";
// import MainLoader from "@/components/MainLoader.vue";

export default {
  data() {
    return {
      loading: true,
      snowflakes: [],
    };
  },
  components: {
    // Snowflake,
    AdFox,
    // MainLoader,
  },
  mounted() {
    // window.addEventListener("load", () => {
    //   this.loading = false;
    // });
  },
};
</script>

<style lang="scss">
.v-toast
  .v-toast--top
  .v-toast__item
  .v-toast__item--error
  .v-toast__item--top {
  z-index: 999999 !important;
}
alert {
  z-index: 999999 !important;
}
</style>
