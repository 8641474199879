import axios from "axios";

const url = window.location.href;
if (url.indexOf("https://kontrakt.edu.uz") > -1) {
    axios.defaults.baseURL = "https://kontrakt-api.edu.uz/";
} else if (url.indexOf("https://kontrakttest.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontrakttest-api.edu.uz/";
} else if (url.indexOf("http://kontrakttest.edu.uz/") > -1) {
    axios.defaults.baseURL = "http://kontrakt-api.edu.uz/";
} else if (url.indexOf("http://oldkontrakt.edu.uz/") > -1) {
    axios.defaults.baseURL = "http://kontraktnext-api.edu.uz/";
} else if (url.indexOf("https://kontraktnext.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
} else if (url.indexOf("https://oldkontrakt.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
} else if (url.indexOf("http://mymyb-prod.apptest.uz/") > -1) {
    axios.defaults.baseURL = "http://mymvb-api.apptest.uz/";
} else if (url.indexOf("https://mymyb-prod.apptest.uz/") > -1) {
    axios.defaults.baseURL = "https://mymvb-api.apptest.uz/";
} else {
    axios.defaults.baseURL = "https://kontrakt-api.edu.uz/";
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
}

const requestInterceptor = (request) => {
    request.withCredentials = true;
    return request;
};
axios.interceptors.request.use((request) => requestInterceptor(request));
